import { Link } from 'react-router-dom'
import { NavLinkIcon } from '../components/common/NavLinkIcon'
import { FacebookIcon, InstaIcon, TwitterIcon } from '../components/common'

import './Homepage.scss'
import { DataIcon, SecureIcon, UpTrendGraphIcon } from '../components/common/Icon/MiscIcon'

export const Homepage = () => {
  return (
    <>
    <div className='home-navbar d-flex'>
      <div className='logo-container'>
        <Link to='/'>
          <img src="./wager-trends.png" alt="wager-trends logo" className='nav-logo'/>
        </Link>
      </div>
      <div className='home-nav flex-grow-1'>
        <NavLinkIcon path='mlb' type='mlb' />
        <NavLinkIcon path='nhl' type='nhl' />
        <NavLinkIcon path='nfl' type='nfl' />
        <NavLinkIcon path='ncaaf' type='ncaaf' />
        <NavLinkIcon path='nba' type='nba' />
      </div>
    </div>

    <div className='call-to-action-container'>
      <div className='inner-content'>
        <div className='row h-100'>
          <div className='left-panel col-lg-7 col-s-12'>
            <h1 className='main-title'>
              We take care of the statistics, you relax.
            </h1>
            <p className='sub-title'>
              Wager Trends provides you with data insights, so you
              have the competitive edge when making wagers.
            </p>
            <Link to='mlb'>
              <button className='get-started'>Get Started</button>
            </Link>
            <div className='social-container row'>
              <div className='follow-text col-sm-5'>follow us on</div>
              <Link to='https://facebook.com' className='col-2'>
                <FacebookIcon style={{width: 32, height: 32, marginRight: 16}} />
              </Link>
              <Link to='https://twitter.com' className='col-2'>
                <TwitterIcon style={{width: 32, height: 32, marginRight: 16}}/>
              </Link>
              <Link to='https://instagram.com' className='col-2'>
                <InstaIcon style={{width: 32, height: 32, marginRight: 16}}/>
              </Link>              
            </div>
          </div>

          <div className='d-none d-lg-block col-lg-5 image-container'>
            <img src="./wt-2.png" alt="wager trends ui sample 2" style={{position: 'absolute', marginBottom: '4rem', right: 0, width: '80%'}}/>
            <img src="./wt-1.png" alt="wager trends ui sample 1" style={{position: 'absolute', marginTop: '4rem', left: 0, width: '80%'}}/>
          </div>
        </div>
      </div>
    </div>

    <div className='value-card-container'>
      <div className='row'>
        <div className='col-sm-5 value-text-container'>
          <h3 className='value-main-header'>Why Choose Us?</h3>
          <p>
            Whether you're a casual bettor looking to enhance your enjoyment of the game or a seasoned pro aiming to maximize your profits, 
            Wager Trends is your ultimate partner in building out your winning strategy.
          </p>
          <p>
            Join our community today to take your sports betting experience to the next level!
          </p>
        </div>
        <div className='col-sm-2 value-card'>
          <UpTrendGraphIcon width={50} height={50}/>
          <h5 className='value-title'>Data-Driven Trends</h5>
          <p className='value-subtitle'>
            Gain access to unconventional charts and tools that can give you a competitive edge in the sports betting world
          </p>
        </div>
        <div className='col-sm-2 value-card'>
          <DataIcon width={50} height={50}/>
          <h5 className='value-title'>Tracking</h5>
          <p className='value-subtitle'>
            We do all the work in tracking daily odds and results to cut down your time spent on research
          </p>
        </div>
        <div className='col-sm-2 value-card'>
          <SecureIcon width={50} height={50}/>
          <h5 className='value-title'>User Focused</h5>
          <p className='value-subtitle'>
            Our intuitive interfaces makes it easy for both sports betting beginners and experienced bettors to navigate our platform
          </p>
        </div>
      </div>
    </div>
    </>
  )
}