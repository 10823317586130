import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { store } from './store'

import { Homepage } from "./pages/Homepage";
import { Provider } from "react-redux";

import './App.scss';
import { CommonPage, DataExplorer } from "./pages/common";
import { VALID_SPORTS } from "./models";

import { 
  useGetAllTeamsQuery, 
  useGetGamesOnlyQuery,
  useGetAllGamesQuery,
  useGetRawGameDataQuery,
  useGetGamesByTeamIdQuery,
} from "./services"

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Homepage />
    )
  },
  {
    path: '/mlb/data-explorer',
    element: (
      <DataExplorer 
        key={'mlb'}
        sport={VALID_SPORTS.MLB}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/mlb/*',
    element: (
      <CommonPage 
        key={'mlb'}
        sport={VALID_SPORTS.MLB}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
      />
    )
  },
  {
    path: '/nhl/data-explorer',
    element: (
      <DataExplorer 
        key={'nhl'}
        sport={VALID_SPORTS.NHL}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/nhl/*',
    element: (
      <CommonPage 
        key={'nhl'}
        sport={VALID_SPORTS.NHL}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
      />
    )
  },
  {
    path: '/nfl/data-explorer',
    element: (
      <DataExplorer 
        key={'nfl'}
        sport={VALID_SPORTS.NFL}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/nfl/*',
    element: (
      <CommonPage 
        key={'nfl'}
        sport={VALID_SPORTS.NFL}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
      />
    )
  },
  {
    path: '/ncaaf/data-explorer',
    element: (
      <DataExplorer 
        key={'ncaaf'}
        sport={VALID_SPORTS.NCAAF}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/ncaaf/*',
    element: (
      <CommonPage 
        key={'ncaaf'}
        sport={VALID_SPORTS.NCAAF}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
      />
    )
  },
  {
    path: '/nba/data-explorer',
    element: (
      <DataExplorer 
        key={'nba'}
        sport={VALID_SPORTS.NBA}
        getAllGamesQuery={useGetAllGamesQuery}
        getRawGameDataQuery={useGetRawGameDataQuery}
      />
    )
  },
  {
    path: '/nba/*',
    element: (
      <CommonPage 
        key={'nba'}
        sport={VALID_SPORTS.NBA}
        getGamesQuery={useGetGamesOnlyQuery}
        getAllGamesQuery={useGetAllGamesQuery}
        getAllTeamsQuery={useGetAllTeamsQuery}
        getGamesByTeamIdQuery={useGetGamesByTeamIdQuery}
      />
    )
  }
])

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
