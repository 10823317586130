import { CSSProperties, PropsWithChildren } from "react"

import './LoadingOverlay.scss'

export interface LoadingOverlayProps {
  loading?: boolean, 
  error?: boolean, 
  noData?: boolean
  width?: CSSProperties['width'],
  height?: CSSProperties['height']
  style?: CSSProperties
}

export const LoadingOverlay = (props: PropsWithChildren<LoadingOverlayProps>) => {
  const { loading, error, noData, width, height, style, children} = props

  return (loading || error || noData) ? (
    <div className='loading-overlay' style={{width, height, ...style}}>
      {
        error
        ? <div className="error">Error</div>
        : loading
        ? <div className='spinner-border text-light' role='status'><span className='sr-only'></span></div>
        : noData
        ? <div className="no-data">No Data</div>
        : <></>
      }
    </div>
  ) : <>{children}</>
}