import { useLocation, useNavigate } from 'react-router-dom'

import { useEffect, useState } from "react"
import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { useAppDispatch } from "../../hooks";
import { setTeams } from '../../redux/teamsSlice';

import { AwayTeamLoss, AwayTeamWin, DateTeamPicker, HomeTeamLoss, HomeTeamWin } from "../../components/common"
import { OddsSelector } from "../../components/common/OddsSelector"
import { TeamSelector } from "../../components/common/TeamSelector"
import { Wrapper } from "../../components/common/Wrapper"
import { DateRange, DateSelector } from "../../components/common/DateSelector"
import { SpreadFilter, SpreadRange } from "../../components/common/SpreadFilter"
import { GAME_ODDS, TeamData, VALID_SPORTS } from "../../models"
import { GameData, SECONDARY_NAV, SUB_PAGE } from "../../models/common"
import { Streaks } from "./Streaks"

import '../../styles/page.scss'
import { Line_Movement } from './Line_Movement'
import { Public } from './Public'
import { Match_Up } from './Match_Up'

export * from './Data_Explorer'

export interface CommonPageProps {
  sport: VALID_SPORTS,
  getGamesQuery: UseQuery<QueryDefinition<{sportKey: string, league: string}, any, never, GameData[], string>>,
  getAllTeamsQuery: UseQuery<QueryDefinition<any, any, never, TeamData[], string>>,
  getAllGamesQuery: UseQuery<QueryDefinition< {marketKey: string, sportKey: string, league: string, includePrices: boolean}, any, never, GameData[], string>>,
  getGamesByTeamIdQuery: UseQuery<QueryDefinition<{teamId: string, marketKey: string, sportKey: string, league: string, includePrices: boolean}, any, never, GameData[], string>>,
}

export const mapSubPageToApiKey = (subPage: SUB_PAGE): 'h2h' | 'spreads' | 'totals' => {
  switch (subPage) {
    case SUB_PAGE.TOTAL:
      return 'totals'
    case SUB_PAGE.SPREAD:
      return 'spreads'
    case SUB_PAGE.MONEYLINE:
    default:
      return 'h2h'
  }
}

export const mapSportToApiKey = (sport: VALID_SPORTS): {sportKey: string, league: string} => {
  switch (sport) {
    case VALID_SPORTS.MLB:
      return {
        sportKey: 'baseball',
        league: 'mlb'
      }
    case VALID_SPORTS.NBA:
      return {
        sportKey: 'bsktbl',
        league: 'nba'
      }
    case VALID_SPORTS.NFL:
      return {
        sportKey: 'football',
        league: 'nfl'
      }
    case VALID_SPORTS.NCAAF:
      return {
        sportKey: 'football',
        league: 'fbs'
      }
    case VALID_SPORTS.NHL:
      return {
        sportKey: 'hockey',
        league: 'nhl'
      }
    default:
      return {
        sportKey: '',
        league: ''
      }
  }
}

export const CommonPage = (props: CommonPageProps) => {
  const { sport, getGamesQuery, getAllTeamsQuery, getAllGamesQuery, getGamesByTeamIdQuery } = props
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [ secondaryNavOption, setSecondaryNavOption ] = useState<SECONDARY_NAV>(SECONDARY_NAV.STREAKS)
  const [ subPage, setSubPage ] = useState<SUB_PAGE | null>(null)
  const [ currentOdds, setCurrentOdds ] = useState<GAME_ODDS>(GAME_ODDS.FULL_TIME)
  const [ dateRange, setDateRange ] = useState<DateRange>({
    end: new Date(),
    start: new Date(Date.now() - 180*24*60*60*1000) // 180 days ago
  })
  const [spreadRange, setSpreadRange] = useState<SpreadRange>({
    low: 0,
    high: Number.POSITIVE_INFINITY
  })

  // Hack to show calendar on mobile, since we can't independently set scrollX and scrollY in CSS :(
  const [calendarOpen, setCalendarOpen] = useState(false)

  const { 
    data: allTeamsData, 
    error: allTeamsError, 
    isLoading: allTeamsLoading 
  } = getAllTeamsQuery(mapSportToApiKey(sport).league)

  useEffect(() => {
    const currentPath = pathname.split("/")[1] || null
    if (
      currentPath && 
      Object.values(SECONDARY_NAV).includes(currentPath as SECONDARY_NAV) &&
      secondaryNavOption !== currentPath
    ) {
      setSecondaryNavOption(currentPath as SECONDARY_NAV)
    }
  }, [pathname])
  
  useEffect(() => {
    // Set default sub nav on secondary nav swap
    setSubPage(
      secondaryNavOption === SECONDARY_NAV.PUBLIC 
        ? SUB_PAGE.MONEYLINE
        : secondaryNavOption === SECONDARY_NAV.LINE_MOVEMENT
        ? SUB_PAGE.MONEYLINE
        : secondaryNavOption === SECONDARY_NAV.STREAKS
        ? SUB_PAGE.MONEYLINE
        : null
      )

    navigate(`./${secondaryNavOption}`)
  }, [ secondaryNavOption ])

  return (
    <Wrapper 
      secondaryNavOptions={[
        SECONDARY_NAV.STREAKS,
        SECONDARY_NAV.LINE_MOVEMENT,
        SECONDARY_NAV.PUBLIC,
        SECONDARY_NAV.MATCH_UP
      ]}
      selectedSecondaryNav={secondaryNavOption}
      onSelectSecondaryNav={(navOption) => setSecondaryNavOption(navOption as SECONDARY_NAV)}
      primaryNavOptions={[
        {
          path: '/',
          type: 'home'
        },
        {
          path: '/mlb/',
          type: 'mlb',
          selected: sport === VALID_SPORTS.MLB
        },
        {
          path: '/nhl/',
          type: 'nhl',
          selected: sport === VALID_SPORTS.NHL
        },
        {
          path: '/nfl/',
          type: 'nfl',
          selected: sport === VALID_SPORTS.NFL
        },
        {
          path: '/ncaaf/',
          type: 'ncaaf',
          selected: sport === VALID_SPORTS.NCAAF
        },
        {
          path: '/nba/',
          type: 'nba',
          selected: sport === VALID_SPORTS.NBA
        },
      ]}
      sidenavContent={[
        {
          title: 'Team Selection',
          content:  (
            <TeamSelector 
              sport={sport}
              getAllTeamsQuery={getAllTeamsQuery}
              getGamesQuery={getGamesQuery}
            />
          )
        },
        {
          title: 'Game Odds',
          content: (
            <OddsSelector 
              selectedOdds={currentOdds}
              onSelectOdds={setCurrentOdds}
            />
          )
        },
        {
          title: 'Date Range',
          content: (
            <DateSelector 
              selectedDateRange={dateRange}
              onSelectDateRange={setDateRange}
            />
          )
        },
        {
          title: 'Spread Filter',
          content: (
            <SpreadFilter 
              currentSpreadRange={spreadRange}
              onSelectSpread={setSpreadRange}
            />
          )
        },
      ]}
    >
      <div className='sub-page-bar' style={{overflowX: calendarOpen ? 'visible' : 'scroll'}}>
        <div className='d-flex flex-row scrollable'>
          <div>
            {
              secondaryNavOption === SECONDARY_NAV.STREAKS ? 
              <DateTeamPicker 
                style={{marginLeft: '1rem'}}
                onClickCalendar={setCalendarOpen}
              /> :
              <div className="sub-page-breadcrumb">
                {sport} &gt; {secondaryNavOption}{subPage ? ` > ${subPage}` : ''}
              </div>
            }
            <div className="sub-page-selector">
              {
                Object.values(SUB_PAGE)
                  .filter(subPageName => {
                    switch (secondaryNavOption) {
                      case SECONDARY_NAV.PUBLIC:
                        // Hide 'all' value on moneyline page
                        return subPageName !== SUB_PAGE.ALL
                      case SECONDARY_NAV.LINE_MOVEMENT:
                        // Hide 'all' value on line movement page
                        return subPageName !== SUB_PAGE.ALL
                      case SECONDARY_NAV.STREAKS:
                        // Hide 'all' value on streaks page
                        return subPageName !== SUB_PAGE.ALL
                      default: 
                        // Hide subnav on all other pages
                        return false
                    }
                  })
                  .map(subPageName => (
                    <div
                      key={subPageName}
                      className={`sub-page-tab ${subPage === subPageName ? 'selected' : ''}`}
                      onClick={() => {setSubPage(subPageName)}}
                    >
                      {subPageName}
                    </div>
                  ))
              }
            </div>
          </div>

          {secondaryNavOption === SECONDARY_NAV.STREAKS && <div className="streaks-legend ms-3">
            <div className="row">
              <div className="col-4">
                <HomeTeamWin height={"1rem"}/> Home Game Win
              </div>
              <div className="col-4">
                <HomeTeamLoss height={"1rem"} /> Home Game Loss
              </div>
              <div className="col-4">
                <AwayTeamWin height={"1rem"} number={1}/> Number of Push
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-4">
                <AwayTeamWin height={"1rem"} /> Away Game Win
              </div>
              <div className="col-4">
                <AwayTeamLoss height={"1rem"} /> Away Game Loss
              </div>
            </div>
          </div>}
        </div>
      </div>
      <div className="d-flex flex-wrap" style={{marginTop: '5rem'}}>
        {(() => {
          switch (secondaryNavOption) {
            case SECONDARY_NAV.STREAKS:
              return (
                <Streaks 
                  sport={sport}
                  subPage={subPage || SUB_PAGE.MONEYLINE}
                  currentOdds={currentOdds}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  spreadRange={spreadRange}
                  allTeams={allTeamsData}
                  getGamesByTeamIdQuery={getGamesByTeamIdQuery}
                />
              )
            case SECONDARY_NAV.LINE_MOVEMENT:
              return (
                <Line_Movement 
                  sport={sport}
                  getAllGamesQuery={getAllGamesQuery}
                  subPage={subPage || SUB_PAGE.MONEYLINE}
                  currentOdds={currentOdds}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  spreadRange={spreadRange}
                  allTeams={allTeamsData}
                />
              )
            case SECONDARY_NAV.PUBLIC:
              return (
                <Public 
                  sport={sport}
                  subPage={subPage || SUB_PAGE.MONEYLINE}
                  currentOdds={currentOdds}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  spreadRange={spreadRange}
                  allTeams={allTeamsData}
                  getAllGamesQuery={getAllGamesQuery}
                />
              )
            case SECONDARY_NAV.MATCH_UP:
              return (
                <Match_Up 
                  sport={sport}
                  currentOdds={currentOdds}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  spreadRange={spreadRange}
                  allTeams={allTeamsData}
                  getGamesQuery={getGamesQuery}
                  getAllGamesQuery={getAllGamesQuery}
                />
              )
            default:
              return <div>No Page Selected</div>
          }
        })()}
      </div>
      
    </Wrapper>
  )
}