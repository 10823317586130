import React, {CSSProperties, forwardRef } from 'react'
import DatePicker from "react-datepicker";

import { Calendar, ChevronDoubleRight, ChevronDoubleLeft } from 'react-bootstrap-icons'

import "react-datepicker/dist/react-datepicker.css";
import './DatePicker.scss'

export interface datePickerProps {
  selectedDate: Date
  onSelectDate?: (selectedDate: Date) => void 
}

export const SingleDatePicker = (props: datePickerProps) => {
  const { selectedDate, onSelectDate } = props

  const handleChangeDate = (date: Date | null) => {
    if (!date) {
      return
    }

    onSelectDate && onSelectDate(date)
  }

  const handleArrowClick = (type: 'increase' | 'decrease') => {
    if (type === 'increase') {
      handleChangeDate(new Date(selectedDate.getTime() + 24*60*60*1000))
    } else if (type === 'decrease') {
      handleChangeDate(new Date(selectedDate.getTime() - 24*60*60*1000))
    }
  }

  const DatePickerCustomInput = forwardRef<HTMLButtonElement, any>(({ value, onClick }, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const today = new Date()
    const monthString = String(today.getMonth() + 1).padStart(2, '0')
    const dayString = String(today.getDate()).padStart(2, '0')
    const yearString = String(today.getFullYear())
    const valueIsToday = `${monthString}/${dayString}/${yearString}` == value
    
    return <button
      className='date-picker'
      onClick={onClick}
      ref={ref}
    >
      {valueIsToday ? 'Today' : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][new Date(value).getDay()]}
      <Calendar />
    </button>
  })

  return (
    <div className='date-picker'>
      <div className='d-flex align-items-center'>
        <ChevronDoubleLeft 
          className='side-date-arrow' 
          onClick={() => {handleArrowClick('decrease')}}
        />
        <DatePicker 
          dateFormat="MM/dd/yyyy"
          selected={selectedDate}
          onChange={(date) => {handleChangeDate(date)}}
          customInput={<DatePickerCustomInput />}
          todayButton="Today"
        />
        <ChevronDoubleRight 
          className='side-date-arrow'
          onClick={() => {handleArrowClick('increase')}}
        />
      </div>
    </div>
  )
}