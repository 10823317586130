import { configureStore } from '@reduxjs/toolkit'

import { 
  gameApi,
  teamApi
} from './services'
import teamsReducer from './redux/teamsSlice'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

export const store = configureStore({
  reducer: {
    [gameApi.reducerPath]: gameApi.reducer,
    [teamApi.reducerPath]: teamApi.reducer,
    teams: teamsReducer
  },
  middleware: (geDefaultMiddleware) => (
    geDefaultMiddleware()
      .concat(teamApi.middleware)
      .concat(gameApi.middleware)
  )
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch