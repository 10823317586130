import { Link } from 'react-router-dom'

import { NavLinkIcon, NavLinkIconProps } from '../NavLinkIcon'


import './Navbar.scss'
import { useState } from 'react'

export interface NavbarProps {
  primaryNavOptions: {
    type: NavLinkIconProps['type'],
    path: string,
    selected?: boolean
  }[],
  secondaryNavOptions: string[],
  selectedSecondaryNavOption?: string,
  handleSelectSecondarynav?: (navOption: string) => void
}

export const Navbar = (props: NavbarProps) => {
  const { primaryNavOptions, secondaryNavOptions, selectedSecondaryNavOption, handleSelectSecondarynav } = props

  const [selectedSecondaryNav, setSelectedSecondaryNav] = useState<string | null >(selectedSecondaryNavOption || secondaryNavOptions[0])

  const handleClickNav = (location: string) => {
    setSelectedSecondaryNav(location)
    handleSelectSecondarynav && handleSelectSecondarynav(location)
  }

  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-dark wt-main-navbar'>
        <div className='container-fluid p-lg-0'>
          <Link to='/' className='navbar-brand p-lg-0 m-lg-0'>
            <img src="/wager-trends.png" alt="wager-trends logo" className='nav-logo'/>
          </Link>

          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          <div 
            className='collapse navbar-collapse flex-column align-items-start'
            id='navbarSupportedContent'
          >
            <div>
              <ul className='navbar-nav me-auto'>
                {
                  primaryNavOptions.map(navOption => (
                    <li 
                      className='nav-item py-0 me-lg-3 my-2'
                      key={navOption.type}
                    >
                      <NavLinkIcon 
                        path={navOption.path} 
                        type={navOption.type} 
                        disableMargin={true}
                        selected={navOption.selected}
                      />
                    </li>
                  ))
                }
              </ul>
            </div>

            <div
              className='wt-bottom-nav-container'
            >
              <ul className='navbar-nav me-auto'>
                {
                  secondaryNavOptions.map(navOption => (
                    <li 
                      className='nav-item  m-lg-2 mt-lg-1 me-lg-4'
                      onClick={() => {handleClickNav(navOption)}}
                      key={navOption}
                    >
                      <span 
                        className={`nav-link py-0 ${selectedSecondaryNav === navOption ? 'selected' : ''}`}
                      >{navOption.replaceAll('-', ' ')}</span>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>

        </div>
      </nav>
    </>
  )
}