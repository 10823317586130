import React, { PropsWithChildren } from 'react'

import './Card.scss'

export interface CardProps {
  width?: number,
  height?: number,
  style?: React.CSSProperties,
  classList?: string
}

export const Card = (props: PropsWithChildren<CardProps>) => {
  const {width, height, style, classList, children} = props

  return (
    <div 
      className={`wager-trends-card ${classList}`}
      style={{
        ...style,
        width,
        height
      }}
    >
      {children}
    </div>
  )
}
