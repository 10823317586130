import { PropsWithChildren, useEffect, useState } from 'react'
import { Offcanvas } from 'bootstrap'
import './SideNav.scss'

export interface SideNavProps {
  expanded: boolean
}

export const SideNav = (props: PropsWithChildren<SideNavProps>) => {
  const { expanded, children } = props
  const [ bsInstance, setBsInstance ] = useState<Offcanvas | null>(null)

  useEffect(() => {
    const sidenav = new Offcanvas(
      document.getElementById('sidenav_offcanvas') || ''
    )
    if (!sidenav) {
      return
    }

    setBsInstance(sidenav)
  }, [])

  useEffect(() => {
    if (!bsInstance) {
      return
    }

    if (expanded) {
      bsInstance.show()
    } else {
      bsInstance.hide()
    }
  }, [expanded])

  return (
    <div
      id='sidenav_offcanvas'
      className={`offcanvas offcanvas-start wt-sidenav ${expanded ? 'show' : ''}`}
      data-bs-scroll="true" 
      data-bs-backdrop="false" 
      tabIndex={-1}
    >
      {children}
    </div>
  )
}